import React from "react";
import { decryption } from "libs/hash";
import { Navigate, Outlet } from "react-router-dom";

type PrivateRouteProps = {
	allowedRoles: string[] | string;
};

export default function PrivateRoute({ allowedRoles }: PrivateRouteProps) {
	const role = JSON.parse(decryption(localStorage.getItem("userData")))?.role;

	return role && allowedRoles.includes(role) ? <Outlet /> : <Navigate to="/forbidden" />;
}
