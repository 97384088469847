import customAxios from "libs/customAxios";
import useFetch from "hooks/useFetch";
import useConfirm from "hooks/useConfirm";

const useSignOutAPI = () => {
	const { setConfirmModal } = useConfirm();
	const { request } = useFetch({
		requestFn: () => {
			return customAxios({
				method: `delete`,
				endpoint: `/user/sign-out`,
			});
		},
		onSuccess: () => {
			localStorage.removeItem("userData");
			window.location.href = "/";
		},
		onError: e => {
			setConfirmModal({
				content: e.detail,
				rightBtn: {
					label: "확인",
					event: () => {
						localStorage.removeItem("userData");
						window.location.href = "/";
					},
				},
			});
		},
	});

	return request;
};

export const SignOutAPI = useSignOutAPI;
