import { useRef, useState, useEffect } from "react";
import { SignOutAPI } from "./apis";

export default function useHeader() {
	const menuRef = useRef(null);
	const [isOpenMenu, setIsOpenMenu] = useState<string>("");
	const [isOpenUserPopUp, setIsOpenUserPopUp] = useState<boolean>(false);
	const request = SignOutAPI();

	const handleUserPopUp = () => setIsOpenUserPopUp(prev => !prev);

	const handleCloseUserPopUp = () => setIsOpenUserPopUp(false);

	const handleSignOut = () => {
		request();
	};

	const handleClickOutside = event => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setIsOpenMenu("");
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return {
		menuRef,
		isOpenMenu,
		isOpenUserPopUp,
		setIsOpenMenu,
		handleUserPopUp,
		handleCloseUserPopUp,
		handleSignOut,
	};
}
