import React from "react";

const WorkSetList = React.lazy(() => import("pages/WorkSet/templates/List"));
const WorkSetGenerate = React.lazy(() => import("pages/WorkSet/templates/Generate"));
const WorkSetDetail = React.lazy(() => import("pages/WorkSet/templates/Detail"));
const WorkPlace = React.lazy(() => import("pages/WorkSet/templates/WorkPlace"));

export const WorkSet = {
	List: WorkSetList,
	Generate: WorkSetGenerate,
	Detail: WorkSetDetail,
	WorkPlace,
};
