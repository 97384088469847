/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef } from "react";
import { BaseTypes, ButtonTypes } from "types/common";
import { COLOR, DISABLED, BUTTONLAYOUT } from "styles/common";

const Button = {
	Wrapper: ({ children, ...props }: BaseTypes) => <div {...props}>{children}</div>,

	Button: forwardRef(
		(
			{ type = "button", children, customStyle, onClick, ...props }: ButtonTypes,
			ref: ForwardedRef<HTMLButtonElement>,
		) => {
			const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
				e.preventDefault();
				onClick?.(e);
			};

			return (
				<button
					css={styledButton(customStyle)}
					ref={ref}
					type={type}
					onClick={handleClick}
					{...props}
				>
					{children}
				</button>
			);
		},
	),
};

export default Button;

const DISABLEDSTYLE = {
	...DISABLED,
	border: `1px solid ${COLOR.gray400}`,
	backgroundColor: "transparent",
	"&:hover": {
		backgroundColor: "transparent",
	},
};

const filledButtonStyle = {
	...BUTTONLAYOUT,
	padding: "0.5rem 1rem",
	color: "white",
	backgroundColor: COLOR.main,
	border: `1px solid ${COLOR.main}`,
	borderRadius: "5px",
	"&:disabled": {
		...DISABLEDSTYLE,
	},
	"&:hover": {
		backgroundColor: "#00328A",
	},
};

const outlinedButtonStyle = {
	...BUTTONLAYOUT,
	padding: "0.5rem 1rem",
	color: COLOR.gray900,
	backgroundColor: "white",
	border: `1px solid ${COLOR.gray900}`,
	borderRadius: "5px",

	"&:disabled": {
		...DISABLEDSTYLE,
	},
};

const textButtonStyle = {
	...BUTTONLAYOUT,

	"&:": {
		color: COLOR.gray900,
	},

	"&:hover": {
		color: COLOR.main,
	},

	"&:disabled": {
		...DISABLED,
	},
};

const styledButton = (type?: string) => {
	const convertType = type?.toLowerCase();

	switch (convertType) {
		case "filled":
			return filledButtonStyle;
		case "text":
			return textButtonStyle;
		case "outline":
			return outlinedButtonStyle;
		default:
			return outlinedButtonStyle;
	}
};
