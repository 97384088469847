import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/fonts/index.css";
import "./styles/reset.css";
import "./styles/common.css";

ReactDOM.render(
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
	document.getElementById("root"),
);
