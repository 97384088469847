import React from "react";
import { atom } from "jotai";

export type ConfirmModalType = {
	content: string;
	leftBtn?: {
		disabled?: boolean;
		label?: string;
		event?: (e?: React.MouseEvent) => void;
	};
	rightBtn: {
		disabled?: boolean;
		label: string;
		event: (e?: React.MouseEvent) => void;
	};
};

export const confirmModalAtom = atom<ConfirmModalType>({
	content: "",
	leftBtn: {
		disabled: false,
		label: "",
		event: () => {},
	},
	rightBtn: {
		disabled: false,
		label: "",
		event: () => {},
	},
});
