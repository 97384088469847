import React from "react";

const ProjectList = React.lazy(() => import("pages/Project/templates/List"));
const ProjectGenerate = React.lazy(() => import("pages/Project/templates/Generate"));
const ProjectDetail = React.lazy(() => import("pages/Project/templates/Detail"));
const ProjectUpdate = React.lazy(() => import("pages/Project/templates/Update"));

export const Project = {
	List: ProjectList,
	Generate: ProjectGenerate,
	Detail: ProjectDetail,
	Update: ProjectUpdate,
};
