import axios from "axios";
import { BASE_URL } from "constants/key";
import { Instance } from "types/api";
import ResponseError from "libs/ResponseError";
import { createSearchParams } from "libs/utils";
import { decryption } from "libs/hash";

const api = axios.create({
	baseURL: BASE_URL,
});

api.interceptors.request.use(
	config => {
		const newConfig = { ...config };
		newConfig.headers.Authorization = JSON.parse(decryption(localStorage.getItem("userData")))
			?.accessToken
			? `Bearer ${JSON.parse(decryption(localStorage.getItem("userData"))).accessToken}`
			: "";
		return newConfig;
	},
	err => Promise.reject(err),
);

api.interceptors.response.use(
	response => {
		const res = response.data;

		return res;
	},
	err => {
		const { response } = err;

		if (response.status === 500) {
			return Promise.reject("Network Error");
		}
		return Promise.reject({ ...response.data, status: response.status });
	},
);

const customAxios: Instance = async ({ method, endpoint, params, body }) => {
	try {
		const data = await api({
			method,
			url: `${endpoint}${createSearchParams(params)}`,
			data: body,
		});
		// const {data, status} = await api({
		// 	method,
		// 	url: `${endpoint}${createSearchParams(params)}`,
		// 	data: body,
		// });

		// if (status !== 200 && status !== 201) {
		// 	throw data;
		// }
		return data;
	} catch (e) {
		throw new ResponseError(e);
	}
};

export default customAxios;
