import CryptoJS from "crypto-js";

export const encryption = (value: string) => {
	if (!value) return null;

	const encrypted = CryptoJS.AES.encrypt(value, "kristag1117").toString();

	return encrypted;
};

export const decryption = (value: string) => {
	if (!value) return null;

	const decrypted = CryptoJS.AES.decrypt(value, "kristag1117").toString(CryptoJS.enc.Utf8);

	return decrypted;
};
