import { find, reduce, forEach } from "lodash";
import { BodyContentType, ParameterType } from "types/api";

export function createSearchParams(obj: ParameterType = {}, base?: string) {
	const result = reduce(
		obj,
		(searchParams, value, key) => {
			if (value instanceof Array) {
				searchParams.delete(key);
				forEach(value, item => searchParams.append(key, item.toString()));
			} else if (value !== undefined && value !== null && value !== "") {
				searchParams.set(key, value.toString());
			} else {
				searchParams.delete(key);
			}
			return searchParams;
		},
		new URLSearchParams(base),
	).toString();

	return result === "" ? "" : `?${result}`;
}

export function createFormData(obj: BodyContentType = {}) {
	return reduce(
		obj,
		(form, value, key) => {
			if (value instanceof File) {
				form.append(key, value);
				return form;
			}
			if (value instanceof Array) {
				const isFile = !!find(
					value,
					(item: string | number | File) => item instanceof File,
				);

				if (isFile) {
					forEach(value, item => item instanceof File && form.append(key, item));
					return form;
				}
			}
			// form.append(key, JSON.stringify(value));
			form.append(key, value);
			return form;
		},
		new FormData(),
	);
}

export const checkValid = {
	email(email: string) {
		if (!email) return true;
		const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

		return !!reg.test(email);
	},

	password(password: string) {
		if (!password) return true;
		const reg = /^.{6,40}$/;

		return !!reg.test(password);
	},

	name(name: string) {
		if (!name) return true;
		const reg = /^[가-힣]{1,40}$/;

		return !!reg.test(name);
	},
};

export const handleSignOut = () => {
	localStorage.removeItem("userData");
	window.location.href = "/";
};

export const metadataIdConverter = (id: string) => {
	switch (id) {
		case "nameKor":
			return "name_kor";
		case "nameEng":
			return "name_eng";
		case "type":
			return "type";
		case "createdAt":
			return "created_at";
		case "updatedAt":
			return "updated_at";
		default:
	}
};
export const isKoreanFilter = (value: string) => /^[가-힣ㄱ-ㅎㅏ-ㅣ0-9_ &-]*$/.test(value);
export const isEnglishFilter = (value: string) => /^[a-zA-Z0-9_ &-]*$/.test(value);

export const meataDataTypeConverter = (id: string) => {
	switch (id) {
		case "input":
			return "단답형";
		case "single_select":
			return "단일선택형";
		case "multi_select":
			return "복수선택형";
		case "category":
			return "카테고리형";
		case "tag":
			return "태그형";
		default:
	}
};

export function camelToSnake(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map(v => camelToSnake(v));
	}
	if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((accumulator: any, key: string) => {
			const snakeKey = key.replace(/[A-Z]/g, matches => `_${matches.toLowerCase()}`);
			let value = obj[key];

			if (value !== null && typeof value === "object") {
				value = camelToSnake(value);
			}

			return {
				...accumulator,
				[snakeKey]: value,
			};
		}, {});
	}
	return obj;
}

export function addPostposition(word: string) {
	const lastChar = word[word.length - 1];
	const lastCharCode = lastChar.charCodeAt(0);

	const startKorCode = 0xac00;
	const endKorCode = 0xd7a3;

	if (lastCharCode < startKorCode || lastCharCode > endKorCode) {
		return `${word}가`;
	}

	const finalConsonant = (lastCharCode - startKorCode) % 28;

	return `${word}${finalConsonant === 0 ? "가" : "이"}`;
}
