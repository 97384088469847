import search from "assets/icons/search.svg";
import searchClose from "assets/icons/search_close.svg";

import paginationArrow from "assets/icons/table/paginationArrow.svg";
import paginationArrowDisabled from "assets/icons/table/paginationArrowDisabled.svg";

import error from "assets/icons/table/error.svg";

import modeDark from "assets/icons/darkMode/mode_dark.svg";
import modeLight from "assets/icons/darkMode/mode_light.svg";

import user from "assets/icons/header/user.svg";
import arrowDown from "assets/icons/header/arrow_down.svg";

import checkCircleDarkGreen from "assets/icons/tagging/check_circle.svg";
import skipNext from "assets/icons/tagging/skip_next.svg";
import keep from "assets/icons/tagging/keep.svg";
import share from "assets/icons/tagging/share_windows.svg";
import leftMark from "assets/icons/tagging/left.svg";
import rightMark from "assets/icons/tagging/right.svg";
import hatTriangle from "assets/icons/tagging/hat_triangle.svg";
import triangle from "assets/icons/tagging/triangle.svg";

import arrowDownDark from "assets/icons/arrow_down_dark.svg";
import arrowDownLight from "assets/icons/arrow_down_light.svg";
import arrowTriangleDown from "assets/icons/arrow_triangle_down.svg";
import arrowTriangleDownBlue from "assets/icons/arrow_triangle_down_blue.svg";
import arrowBackLight from "assets/icons/arrow_back_light.svg";
import arrowBackDark from "assets/icons/arrow_back_dark.svg";
import iconArrowForward from "assets/icons/icon_arrow forward.svg";
import checked from "assets/icons/checked.svg";
import checkCircle from "assets/icons/check_circle.svg";
import authorityWarning from "assets/icons/authority_warning.svg";
import shortcut from "assets/icons/shortcut.svg";
import deleteIcon from "assets/icons/delete.svg";
import deleteNoMargin from "assets/icons/delete_no_margin.svg";
import deleteNoMarginWhite from "assets/icons/delete_no_margin_white.svg";
import trashCan from "assets/icons/trash_can.svg";
import trashCanNoMargin from "assets/icons/trash_can_no_margin.svg";
import dragHandleHorizontal from "assets/icons/drag_handle_horizontal.svg";
import dragHandleVertical from "assets/icons/drag_handle_vertical.svg";
import kebab from "assets/icons/kebab.svg";
import close from "assets/icons/close.svg";

export const searchIcons = {
	search,
	close: searchClose,
};

export const pagination = {
	arrow: paginationArrow,
	arrowDisabled: paginationArrowDisabled,
};

export const table = {
	error,
};

export const darkModeIcons = {
	dark: modeDark,
	light: modeLight,
};

export const headerIcons = {
	user,
	arrowDown,
};

export const tagging = {
	checkCircleDarkGreen,
	skipNext,
	keep,
	share,
	leftMark,
	rightMark,
	hatTriangle,
	triangle,
};

export const icons = {
	arrowDownDark,
	arrowDownLight,
	checked,
	checkCircle,
	authorityWarning,
	shortcut,
	deleteIcon,
	deleteNoMargin,
	deleteNoMarginWhite,
	arrowTriangleDown,
	arrowTriangleDownBlue,
	trashCan,
	trashCanNoMargin,
	dragHandleHorizontal,
	dragHandleVertical,
	kebab,
	arrowBackLight,
	arrowBackDark,
	close,
	iconArrowForward,
};
