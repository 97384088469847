import { Navigate, Outlet } from "react-router-dom";
import { decryption } from "libs/hash";

type RestrictRouteProps = {
	allow: boolean;
	redirect: string;
};

export default function RestrictRoute({ allow = false, redirect = "/" }: RestrictRouteProps) {
	if (!allow) return <Navigate to={redirect} />;
	return <Outlet />;
}

export const isSignIn =
	JSON.parse(decryption(localStorage.getItem("userData")))?.accessToken !== undefined;
