export const MENUS = {
	worker: [
		{
			id: "workSet",
			label: "워크셋",
			path: "/workSet",
		},
	],
	reviewer: [
		{
			id: "workSet",
			label: "워크셋",
			path: "/workSet",
		},
	],
	manager: [
		{
			id: "metadata",
			label: "메타데이터",
			path: "/metadata",
		},
		{
			id: "project",
			label: "프로젝트",
			path: "/project",
		},
		{
			id: "dataSet",
			label: "데이터셋",
			path: "/dataSet",
		},
		{
			id: "item",
			label: "아이템",
			subMenus: [
				{
					id: "itemManagement",
					label: "아이템 관리",
					path: "item/itemManagement",
				},
				{
					id: "skipManagement",
					label: "스킵 관리",
					path: "item/skipManagement",
				},
			],
		},
		{
			id: "memberManagement",
			label: "사용자관리",
			path: "/memberManagement",
		},
	],
};
