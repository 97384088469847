import React, { Suspense } from "react";
import { Route, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
// import Spinner from "components/modules/Spinner";
import BaseLayout from "components/layout/BaseLayout";
import RestrictRoute, { isSignIn } from "components/layout/RestrictRoute";
import PrivateRoute from "components/layout/PrivateRoute";

import Main from "pages/Main/template";
import { WorkSet } from "pages/WorkSet/templates";
import { Metadata } from "pages/Metadata/template";
import { Project } from "pages/Project/templates";
import { MemberManagement } from "pages/MemberManagement/templates";
import { ItemManagement } from "pages/ItemManagement/templates";
import { SkipManagement } from "pages/SkipManagement/template";

const Forbidden = React.lazy(() => import("components/templates/Forbidden"));
const SignUp = React.lazy(() => import("pages/SignUp/template"));
const SignIn = React.lazy(() => import("pages/SignIn/template"));
const CompleteSignUp = React.lazy(() => import("components/templates/CompleteSignUp"));
const MyPage = React.lazy(() => import("pages/MyPage/template"));
const DataSetList = React.lazy(() => import("pages/DataSet/template"));

const Root = () => (
	<Routes>
		<Route element={<BaseLayout />}>
			<Route path="/" element={<Main />} />
			<Route path="/*" element={<RestrictRoute allow={!isSignIn} redirect="/" />}>
				<Route path="signIn" element={<SignIn />} />
				<Route
					path="signUp/*"
					element={
						<Routes>
							<Route path="" element={<SignUp />} />
							<Route path="completed" element={<CompleteSignUp />} />
						</Routes>
					}
				/>
			</Route>
			<Route path="/*" element={<RestrictRoute allow={isSignIn} redirect="/signIn" />}>
				<Route path="myPage" element={<MyPage />} />
				<Route element={<PrivateRoute allowedRoles={["worker", "reviewer"]} />}>
					<Route path="workSet" element={<WorkSet.List />} />
					<Route path="workPlace/:workSetId" element={<WorkSet.WorkPlace />} />
				</Route>
				<Route element={<PrivateRoute allowedRoles="manager" />}>
					<Route
						path="memberManagement/*"
						element={
							<Routes>
								<Route path="" element={<MemberManagement.List />} />
								<Route path=":id" element={<MemberManagement.Detail />} />
							</Routes>
						}
					/>
					<Route path="dataSet" element={<DataSetList />} />
					<Route
						path="project/*"
						element={
							<Routes>
								<Route path="" element={<Project.List />} />
								<Route path="generate" element={<Project.Generate />} />
								<Route path=":id" element={<Project.Detail />} />
								<Route path=":id/update" element={<Project.Update />} />
								<Route path=":id/generate" element={<WorkSet.Generate />} />
								<Route path=":id/detail/:workSetId" element={<WorkSet.Detail />} />
								<Route
									path=":id/workPlace/:workSetId"
									element={<WorkSet.WorkPlace />}
								/>
							</Routes>
						}
					/>
					<Route
						path="metadata/*"
						element={
							<Routes>
								<Route path="" element={<Metadata.List />} />
								<Route path="generate" element={<Metadata.Generate />} />
								<Route path=":id" element={<Metadata.Detail />} />
							</Routes>
						}
					/>
					<Route
						path="item/*"
						element={
							// <Suspense fallback={<Spinner />}>
							<Routes>
								<Route
									path="itemManagement/*"
									element={
										<Routes>
											<Route path="" element={<ItemManagement.List />} />
											<Route
												path="detail"
												element={<ItemManagement.Detail />}
											/>
										</Routes>
									}
								/>
								<Route
									path="skipManagement/*"
									element={
										<Routes>
											<Route path="" element={<SkipManagement.List />} />
											<Route
												path="detail"
												element={<SkipManagement.Detail />}
											/>
										</Routes>
									}
								/>
							</Routes>
							// </Suspense>
						}
					/>
				</Route>
			</Route>
		</Route>
		<Route
			path="forbidden"
			element={
				<Suspense fallback={null}>
					<Forbidden />
				</Suspense>
			}
		/>
	</Routes>
);

export const router = createBrowserRouter(
	createRoutesFromElements(<Route path="*" element={<Root />} />),
);
