import { RouterProvider } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { HotkeysProvider } from "react-hotkeys-hook";
import useGlobalRejectHandler from "hooks/useGlobalErrorHandler";
import ResponseError from "libs/ResponseError";
import { router } from "pages/Root";

function App() {
	const commonErrorHandle = useGlobalRejectHandler();

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: 0,
				// suspense: true,
			},
		},
		queryCache: new QueryCache({
			onError: e => {
				if (e instanceof ResponseError) commonErrorHandle(e);
			},
		}),
	});

	return (
		<QueryClientProvider client={queryClient}>
			<HotkeysProvider initiallyActiveScopes={["workset"]}>
				<RouterProvider router={router} />
			</HotkeysProvider>
		</QueryClientProvider>
	);
}

export default App;
