import { useCallback, useEffect } from "react";
import { useSetAtom } from "jotai";
import { alertModalAtom } from "jotai/modal/alertModalAtom";

export default function useAlert() {
	const handleModal = useSetAtom(alertModalAtom);

	const setAlertModal = useCallback((content: string) => {
		handleModal(content);
	}, []);

	useEffect(() => () => handleModal(""), []);

	return { setAlertModal };
}
