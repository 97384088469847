/** @jsxImportSource @emotion/react */
import React, { useEffect, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { darkModeAtom } from "jotai/darkMode";
import Header from "components/modules/Header";

const AlertModal = React.lazy(() => import("components/modal/Alert"));
const ConfirmModal = React.lazy(() => import("components/modal/Confirm"));
const SkipModal = React.lazy(() => import("components/modal/Skip"));

export default function BaseLayout() {
	const { pathname } = useLocation();
	const darkMode = useAtomValue(darkModeAtom);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if (darkMode) {
			document.body.classList.add("dark-mode");
		} else {
			document.body.classList.remove("dark-mode");
		}
	}, [darkMode]);

	return (
		<>
			<Header />
			<Suspense fallback={null}>
				<section css={wrapper}>
					<Outlet />
				</section>
				<AlertModal />
				<ConfirmModal />
				<SkipModal />
			</Suspense>
		</>
	);
}

const wrapper = {
	width: "100vw",
	display: "flex",
	justifyContent: "center",
};
