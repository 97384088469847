export interface ApiErrorResponse {
	status: number;
	detail: string;
}

class ResponseError {
	readonly status: ApiErrorResponse["status"];

	readonly detail: ApiErrorResponse["detail"];

	readonly defaultText: string;

	constructor(error: any) {
		const { status, detail } = error;

		this.status = status;
		this.detail = detail;
		this.defaultText = `Error Code ${status}: ${detail}`;
	}
}

export default ResponseError;
