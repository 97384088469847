/** @jsxImportSource @emotion/react */
import { images } from "assets/Images";
import { stylingText } from "libs/styling";

export default function Main() {
	return (
		<div css={wrapper}>
			<div css={leftContainer}>
				<span css={title}>신발 분류를 똑똑하게</span>
				<span css={subTitle}>Kristin AI를 위한 라벨링 플랫폼</span>
			</div>
			<img css={image} src={images.main} alt={"main"} />
		</div>
	);
}

const wrapper = {
	paddingTop: "120px",
	display: "flex",
	alignItems: "center",
	gap: "66px",
};

const leftContainer = { display: "flex", flexDirection: "column" as const, gap: "30px" };

const title = { ...stylingText({ size: "72", weight: "bold" }) };

const subTitle = { ...stylingText({ size: "24", weight: "medium" }) };

const image = { width: "620px", height: "494px" };
